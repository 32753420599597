import * as React from 'react';

import { HtmlContent } from 'client/shared/components/base';
import { ReportTabContent, ReportTabSettingsType, wrap } from 'core';
import { TableauReport } from 'client/shared/components/tableau-report';

export interface ReportItem {
  readonly id: string;
  readonly title: string;
  readonly content: ReportTabContent;
}

export const OutcomeReportItem: React.FC<{
  readonly reportItem: ReportItem;
}> = (p: { readonly reportItem: ReportItem }) => {
  return wrap(() => {
    switch (p.reportItem.content.type) {
      case ReportTabSettingsType.TABLEAU:
        return (
          <div>
            <TableauReport
              options={{
                hideTabs: true,
                hideToolbar: true,
                device: 'phone',
                height: p.reportItem.content.height ?? undefined,
                width: p.reportItem.content.height ? '100%' : undefined,
              }}
              url={p.reportItem.content.tableau_url}
            />
          </div>
        );
      case ReportTabSettingsType.HTML:
        return (
          <div className="px-2">
            <HtmlContent html={p.reportItem.content.raw_html} imageAlt="Report" />
          </div>
        );
      case ReportTabSettingsType.CROSSTABS:
      case ReportTabSettingsType.OVERVIEW:
      case ReportTabSettingsType.QUALITY_IMPORTANCE:
      case ReportTabSettingsType.TOPICS:
      case ReportTabSettingsType.ADVANCED_RESULTS:
      case ReportTabSettingsType.PARTICIPATION:
      case ReportTabSettingsType.RESULTS:
      case ReportTabSettingsType.REPOSTS:
      case ReportTabSettingsType.COMMUNITY_READINESS:
      case ReportTabSettingsType.COMMUNITY_NEEDS:
      case ReportTabSettingsType.ECONOMIC_CONTRIBUTION:
      case ReportTabSettingsType.INTRODUCTION:
      case ReportTabSettingsType.KEY_FINDINGS:
      case ReportTabSettingsType.METHODS:
      case ReportTabSettingsType.UNDERSTANDING_THE_REPORT:
      case ReportTabSettingsType.FULL_TRENDS:
        return <div>Not Yet Implemented</div>;
    }
  });
};
OutcomeReportItem.displayName = 'OutcomeReportItem';
